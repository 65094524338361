import React from 'react';
import styles from "./LoginInputs.module.scss";
import NumberFormat from 'react-number-format';
function NumberFormatInput(props) {
    return (
        <div className={styles.input_validation}>
            <div className={`${styles.login_input_cont} ${styles.forget_input_code}`}>
                <div className={styles.number_input}>
                    <NumberFormat
                        // onKeyDown={(e) => props.onKeyDown(e)}
                        value={props.value}
                        type={props.type}
                        placeholder={props.placeholder}
                        onChange={(e) => {
                            let value = e.target.value.trim();
                            props.onChange(value)

                        }}
                        format="# # # # # #"
                        mask="_" />
                </div>
            </div>
            {!props.valid && props.submitted ? <span className={styles.error_message}>{props.error_message}</span> : null}
        </div>
    )
}
export default NumberFormatInput;